<script>
import { ArrowUpIcon, MapPinIcon,ArrowLeftIcon,GitBranchIcon,MailIcon } from "vue-feather-icons";

import Navbar from "@/components/navbar0";
import Footer from "@/components/footer";

import ResumeHighlight from "../search/ResumeHighlightLess";
import store from "@/store/store";

export default {

  data() {
    return {
      tabs:["Добавлен","Откликнулся","Собеседование"],
      isWhiteNavbar: false,
      hover:false,

      input_get_resumes: {
        user_id: 1,
        vacancy_id:1,
        page: 1,
        resumesPerPageId: 1,
      },

      filterText: '',
      move_resumes_sql: {
        user_id: 1,
        vacancy_id:1,
        resume_ids: [],
        funnel_stage_from: 1,
        funnel_stage_to: 1,
        neighbor: false,
      },
      searchLine: '',


      setSQL_ids : {
        ids_arr: [1,2],
        save_search_id: 1,
        user_id: 2,
      },

      savedSearch: {name:'Базовый',id:0,type_id:0},

      resumeIds : [],
      resumes : [],
      resumes_backup : [],

      total_resume: 5836,

      selectedTrue: true,

      selectAllCheckbox: false,

      funnel_stages: [],
      tabIndex:0,

      funnel_stage_id: 0,

      vacancy_name: '',
      allVacancies: [
        {
          id: 0,
          name: "Дефолт",
        },
      ],
      activeVacancies: [],
      archiveVacancies: [],

      orderBy_Options : [
        {id:1,value:'По дате добавления резюме'},
        {id:2,value:'По дате обновления резюме'},
        {id:3,value:'По убыванию зарплат'},
        {id:4,value:'По возрастанию зарплат'},
        {id:5,value:'По убыванию возраста'},
        {id:6,value:'По возрастанию возраста'},
      ],
      orderBy_Selected : {id:1,value:'По дате добавления резюме'},

      resumesPerPage_Options : [
        {id:1,value:'10 резюме'},
        {id:2,value:'20 резюме'},
        {id:3,value:'50 резюме'},
        {id:4,value:'100 резюме'},
      ],
      resumesPerPage_Selected : {id:1,value:'10 резюме'},

      page: {
        active: 1,
        up: 2,
        down: 1,
        max: 3,
        min: 1,
        left_skip: false,
        right_skip: false,
        prev: true,
        next: true,
        skip_number: 3,
      },
      page_arr:[],
    };
  },

  mounted() {
    //this.getCandidates(this.$route.params.vacancy_id);
    this.getCandidatesFast();
    this.getStages();
  },



  watch: {
    selectAllCheckbox: function (val) {
      if (this.resumes.length > 0) {

        for (let i = 0; i < this.resumes.length; i++) {
          if (val) {
            this.resumes[i].isSelected = true;
          } else {
            this.resumes[i].isSelected = false;
          }
        }
      }
    },

    tabIndex: function (val) {
      if (this.funnel_stages.length > 0) {
        this.funnel_stage_id = this.funnel_stages[val].funnel_stage_order_id;
      }
    },


      filterText: function (val) {
        if(val.length>1){
          console.log('inside filterText')
          var resumesFiltered = this.resumes.filter( (x) => {
                    //console.log(x);
                  var fullname = '';
                  try{ fullname = x.full_name.toLowerCase()} catch(err) { }
                  var email = '';
                try{ email = x.email.toLowerCase()} catch(err) { }
                  if ( fullname.includes(val) || email.includes(val) ){
                  //if ( fullname.includes(val) ) {
                    return x
                  }
          });

          this.resumes = resumesFiltered;
        } else {
          this.resumes = this.resumes_backup;
        }
    },


  },




  methods: {


    paginationUtil(x) {
      this.page.active = parseInt(x);
      this.page.up = Math.min(this.page.active+1, this.page.max);
      this.page.down = Math.max(this.page.active-1, this.page.min);
      if (this.page.active>this.page.min) {
        this.page_arr.push({page: '' + this.page.min, active: 'notactive'});
      }
      if (this.page.active === this.page.min) {
        this.page.prev = false;
      }
      if (this.page.active === this.page.max) {
        this.page.next = false;
      }

      if (this.page.active-this.page.min>=this.page.skip_number) {
        this.page.left_skip = true;
        this.page_arr.push({page:'...',active:'notactive'});
        this.page_arr.push({page:''+this.page.down,active:'notactive'});
        if (this.page.active<this.page.max) {
          this.page_arr.push({page: '' + this.page.active, active: 'active'});
        }
      } else {

        for (var i=2; i<this.page.active; i++) {
          this.page_arr.push({page:''+i,active:'notactive'});
        }
        if (this.page.active<this.page.max) {
          this.page_arr.push({page:''+this.page.active,active:'active'});
        }

      }
      if (this.page.max-this.page.active>=this.page.skip_number) {
        this.page.right_skip = true;
        this.page_arr.push({page:''+this.page.up,active:'notactive'});
        this.page_arr.push({page:'...',active:'notactive'});
        this.page_arr.push({page:''+this.page.max,active:'notactive'});

      } else {
        for (var i = this.page.up; i <= this.page.max; i++) {

          if (this.page.max===i & this.page.active === this.page.max) {
            this.page_arr.push({page: '' + i, active: 'active'});
          } else {
            this.page_arr.push({page: '' + i, active: 'notactive'});
          }
        }

      }
      console.log(this.page_arr);
      console.log(this.page);
      },

    selectCandidates(){
      this.selectedTrue = !this.selectedTrue;
    },

    updateResumeList(e) {
      console.log('inside updateResumeList ' + e)
      if (e==='ok'){
        this.getCandidatesFast();
        this.getStages();
      }
    },


    updateResume(e) {
      console.log('inside updateResume')
      console.log(e);

      this.setSQL_ids.ids_arr = [];
      this.setSQL_ids.ids_arr.push(this.resumes[e].id);
      this.setSQL_ids.save_search_id = this.savedSearch.id;
      this.setSQL_ids.user_id = store.state.user_id;
      console.log(this.setSQL_ids)
      //this.resumes.splice(e,e+1);
      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/advanced-search/get-resume-byIdList-detail?access_token=' + this.$cookies.get("access_token_skillrank"),
        data: this.setSQL_ids,
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then((res_in) => {
        console.log('inside 2nd axios - get resumes')
        console.log(res_in.data);
        if (res_in.data.length) {

          for (var k=0; k<res_in.data.length; k++){
            this.resumes.splice(e,1,res_in.data[k]);
          }

        }
      });
    },


/*
    getCandidates() {

      console.log('inside searchCandidates ' )


      //this.inputSqlContacts.searchButtonIsPressed = 1;
      //this.inputSqlContacts.step = 1

      this.resumeIds = [];
      this.resumes = [];


      this.input_get_resumes.user_id = store.state.user_id;
      this.input_get_resumes.vacancy_id = this.$route.params.vacancy_id;
      //this.input_get_resumes.page = this.$route.params.page;
      //this.input_get_resumes.resumesPerPageId = this.resumesPerPage_Selected.id



      console.log(this.input_get_resumes)

      this.$vs.loading({
        container: this.$refs.resumesListArea,
        scale: 1,
        opacity: 0.1,
      });

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/prod/vacancy/get-resumes?access_token=' + store.state.token,
        data: this.input_get_resumes,
      }).then((res) => {
        console.log(res.data);
        if(res.data.status === 'ok') {
          if (res.data.resume_ids.length) {

            this.page.max = res.data.resume_ids[0].max_page;
            this.total_resume = res.data.resume_ids[0].total_num;
            //this.paginationUtil(this.$route.params.page);
            for (var i=0; i<res.data.resume_ids.length; i++){
              this.resumeIds.push(res.data.resume_ids[i].resume_id);
            }

            console.log(this.resumeIds);


            //this.setSQL_ids.ids_arr = this.resumeIds.slice(0,Math.min(this.totalSearchResults,this.infiniteSearchBy));
            //this.start_index = Math.min(this.totalSearchResults,this.infiniteSearchBy);

            //this.setSQL_ids.ids_arr = this.resumeIds.slice(0, Math.min(this.totalSearchResults, 10));
            this.setSQL_ids.ids_arr = this.resumeIds;
            //this.setSQL_ids.save_search_id = this.savedSearch.id;
            //this.setSQL_ids.user_id = store.state.user_id;
            console.log(this.setSQL_ids)
            if (this.setSQL_ids.ids_arr.length > 0) {
              this.axios({
                method: 'post',
                url: '' + this.$hostname + '/advanced-search/get-resume-byIdList-detail?access_token=' + store.state.token,
                data: this.setSQL_ids,
                headers: {
                  'Access-Control-Allow-Origin': '*'
                }
              }).then((res_in) => {
                console.log('inside 2nd axios - get resumes')
                console.log(res_in.data);
                if (res_in.data.length) {
                  for (var k = 0; k < res_in.data.length; k++) {
                    this.resumes.push(res_in.data[k]);
                  }
                }
                //loading.close();
                this.$vs.loading.close("#div-with-loading > .con-vs-loading");
              }).catch((err) => {
                console.error(err);
                //loading.close();
                this.$vs.loading.close("#div-with-loading > .con-vs-loading");
              });



            }
          } else {


          }

        }

      }).catch((err) => {
        console.error(err);
        loading.close();
        //this.$router.push('prospect-pool')
      });

      //this.saveLatestSearchParameters();


    },
*/

    getCandidatesFast() {

      console.log('inside searchCandidates fast ' )


      this.resumeIds = [];
      this.resumes = [];


      this.input_get_resumes.user_id = store.state.user_id;
      this.input_get_resumes.vacancy_id = this.$route.params.vacancy_id;


      console.log(this.input_get_resumes)

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/prod/vacancy/get-resumes-fast?access_token=' + store.state.token,
        data: this.input_get_resumes,
      }).then((res) => {

            for (var i=0; i<res.data.resume_ids.length; i++){
              this.resumes.push(res.data.resume_ids[i]);
              this.resumes_backup.push(res.data.resume_ids[i]);
            }
        console.log('this.resumes:');
            console.log(this.resumes);

              }).catch((err) => {
                console.error(err);
              });


    },



    getAllVacancies(){

      this.input_save_vacancy.user_id = store.state.user_id;

      //var url = '' + this.$hostname + '/prod/vacancy/get-list?access_token=' + store.state.token;
      var url = '' + this.$hostname + '/prod/job-description/list?access_token=' + store.state.token;
      console.log('inside get all jobs ' + this.input_save_vacancy + '  ' + url)

      this.axios({
        method: 'post',
        url: url,
        data: this.input_save_vacancy,
      }).then((res) => {
        this.allVacancies = res.data.arr;
        this.activeVacancies = this.allVacancies.filter(x => x.status === 'Активная');
        this.archiveVacancies = this.allVacancies.filter(x => x.status === 'Архивная');

        console.log(this.allVacancies);
        console.log(this.activeVacancies);
        /*
          if (this.allVacancies.length) {
            this.savedSearch = this.allVacancies[0];
          }
        */
      });

    },


    PaginationStep(x){

      if (x===-1) {
        let route = this.$router.resolve({path: '/resumes-list/job/'+this.$route.params.vacancy_id+'/page/'+this.page.down});
        window.open(route.href, '_self');
      } else if (x===-2) {
        let route = this.$router.resolve({path: '/resumes-list/job/'+this.$route.params.vacancy_id+'/page/'+this.page.up});
        window.open(route.href, '_self');
      } else {
        let route = this.$router.resolve({path: '/resumes-list/job/'+this.$route.params.vacancy_id+'/page/'+x});
        window.open(route.href, '_self');
      }

    },


    getStages() {

      console.log('inside getStages')
      this.input_get_resumes.user_id = store.state.user_id;
      this.input_get_resumes.vacancy_id = this.$route.params.vacancy_id;
      //this.input_get_resumes.page = this.$route.params.page;

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/prod/vacancy-funnel/get-stage?access_token=' + store.state.token,
        data: this.input_get_resumes,
      }).then((res_in) => {
        if (res_in.data.status === 'ok') {
          this.funnel_stages = res_in.data.funnel_stages;
          this.vacancy_name = res_in.data.vacancy_name;
          console.log('this.funnel_stages:');
          console.log(res_in.data);
        }
      });

    },

    moveResumeToAnotherFunnelStage(funnelStage_from,funnelStage_to) {
      this.move_resumes_sql.resume_ids = [];
      for (let i = 0; i < this.resumes; i++) {
          if (this.resumes[i].isSelected) {
            this.move_resumes_sql.resume_ids.push(this.resumes[i].resume_id)
          }
      }
      this.move_resumes_sql.user_id = store.state.user_id;
      this.move_resumes_sql.vacancy_id = this.$route.params.vacancy_id;
      this.move_resumes_sql.funnel_stage_from = funnelStage_from;
      this.move_resumes_sql.funnel_stage_to = funnelStage_to;
      console.log(this.move_resumes_sql);
      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/prod/vacancy-funnel/move-to-stage?access_token=' + store.state.token,
        data: this.move_resumes_sql,
      }).then((res) => {

        for (var i=0; i<res.data.resume_ids.length; i++){
          this.resumes.push(res.data.resume_ids[i]);
          this.resumes_backup.push(res.data.resume_ids[i]);
        }
        console.log(this.resumes);

      });

    },

  },


  components: {
    ResumeHighlight,
    Navbar,
    //Switcher,
    Footer,
    ArrowUpIcon,
    MapPinIcon,
    ArrowLeftIcon,GitBranchIcon,MailIcon
    //Testimonial,
  },
};
</script>

<template>
  <div>


    <Navbar :nav-light="true" />





<!--
    <header
            class="sticky"
            :class="{ 'bg-white': true }">

      <div class="row m-4">
        <div class="col-lg-3 col-md-6 col-12">

        </div>
        <div class="col-lg-8 col-md-6 col-12">
           <h4> Всего {{ total_resume }} резюме </h4>
          </div>
      </div>
      <div class="row m-4">
        <div class="col-lg-3 col-md-6 col-12">

        </div>
        <div class="col-lg-8 col-md-6 col-12 ">
          <ul class="list-inline mb-0 mt-3">
            <li class="list-inline-item mr-2">
          <div class="buy-button">

              <a
                  class="btn btn-secondary"
                  @click="selectCandidates"
              >Выбрать</a>

        </div>
            </li>
            <li class="list-inline-item mr-2">
          <div class="buy-button">
            <a class="btn btn-primary" @click="selectCandidates">Сделать рассылку</a>
          </div>
            </li>
          </ul>
      </div>
      </div>
      <div class="row ml-4 mr-4">
        <div class="col-lg-3 col-md-6 col-12">

        </div>
      </div>
    </header>
    -->


    <section class="section" >
      <div class="d-flex m-4 justify-content-start align-items-center">
        <!--<a href="/jobs" class="btn btn-icon btn-outline-primary"><arrow-left-icon class="fea icon-sm"></arrow-left-icon></a>-->
        <a href="/jobs" class="text-muted mr-2 text-lg-center"><h1><i class="uil uil-arrow-left"></i></h1>  </a>
        <h2>Вакансия: {{vacancy_name}}</h2>
        <!--
        <div class="form-group ml-5">
        <select class="form-control custom-select" v-model="orderBy_Selected">
          <option v-bind:value="x" v-for="x in orderBy_Options">{{x.value}}</option>
        </select>
        </div>

          <div class="form-group ml-2">
        <select class="form-control custom-select" v-model="resumesPerPage_Selected">
          <option v-bind:value="x" v-for="x in resumesPerPage_Options">{{x.value}}</option>
        </select>
          </div>
          -->
      </div>
      <!--
      <div class="row ml-4 mr-4 list-inline ">
        <h2>Вакансия: jdcnsdlsdjnc</h2>
        <div class="form-group list-inline-item mb-0">
          <select class="form-control custom-select" v-model="orderBy_Selected">
            <option v-bind:value="x" v-for="x in orderBy_Options">{{x.value}}</option>
          </select>
        </div>



        <div class="form-group list-inline-item mb-0">
          <select class="form-control custom-select" v-model="resumesPerPage_Selected">
            <option v-bind:value="x" v-for="x in resumesPerPage_Options">{{x.value}}</option>
          </select>

        </div>
      </div>
      -->
        <div class="row ml-4 mr-4">
          <div class="col-lg-3 col-md-6 col-12">
            <div class="sidebar sticky-bar p-4 rounded shadow">


              <!--
              <div class="widget mb-4 pb-4 border-bottom">
                <h5 class="widget-title">Всего {{ total_resume }} резюме</h5>
              </div>
              -->
                <!--
                <div id="jobkeywords" class="widget-search mt-4 mb-0">
                  <form role="search" method="get" id="searchform" class="searchform">
                    <div>
                      <input type="text" class="border rounded" name="s" id="s" placeholder="Search Keywords...">
                      <input type="submit" id="searchsubmit" value="Search">
                    </div>
                  </form>
                </div>
                -->


              <div class="widget mb-4 pb-2">

                <div class="form-group">
                  <label>Поиск по имени, email</label>
                  <div class="position-relative">

                    <input
                        name="nameto"
                        id="nameto"
                        type="text"
                        class="form-control pl-2"
                        placeholder="Поиск по имени, email ..."
                        v-model="filterText"
                    />
                  </div>
                </div>

                <!--
                <form role="search" method="get" id="searchform" class="searchform">
                  <div>
                    <input type="text" class="border rounded" name="s" id="s" placeholder="Поиск по имени, email ..." v-model="filterText">
                  </div>
                </form>
                -->

                <!--
                  <ul class="list-inline mb-0 mt-3">
                  <li class="list-inline-item mr-2">
                    <div class="buy-button">

                      <a
                          class="btn btn-secondary"
                          @click="selectCandidates"
                      >Выбрать</a>

                    </div>
                  </li>
                  <li class="list-inline-item mr-2">
                    <div class="buy-button">
                      <a class="btn btn-primary" @click="selectCandidates">Сделать рассылку</a>
                    </div>
                  </li>
                </ul>
                  -->
              </div>



              <!--

              <div class="widget mb-4 pb-2">
                <h5 class="widget-title">Tags Cloud</h5>
                <div class="tagcloud mt-4">
                  <a href="jvascript:void(0)" class="rounded">Business</a>
                  <a href="jvascript:void(0)" class="rounded">Finance</a>
                  <a href="jvascript:void(0)" class="rounded">Marketing</a>
                  <a href="jvascript:void(0)" class="rounded">Fashion</a>
                  <a href="jvascript:void(0)" class="rounded">Bride</a>
                  <a href="jvascript:void(0)" class="rounded">Lifestyle</a>
                  <a href="jvascript:void(0)" class="rounded">Travel</a>
                  <a href="jvascript:void(0)" class="rounded">Beauty</a>
                  <a href="jvascript:void(0)" class="rounded">Video</a>
                  <a href="jvascript:void(0)" class="rounded">Audio</a>
                </div>
              </div>


              <div class="widget mb-4 pb-4 border-bottom">
                <h5 class="widget-title">Catagories</h5>
                <div class="form-group mt-4 mb-0">
                  <select class="form-control custom-select" id="job-catagories">
                    <option>All Jobs</option>
                    <option>Digital & Creative</option>
                    <option>Accountancy</option>
                    <option>Banking</option>
                    <option>IT Contractor</option>
                    <option>Graduate</option>
                    <option>Estate Agency</option>
                  </select>
                </div>
              </div>





              <div class="widget mb-4 pb-4 border-bottom">
                <h5 class="widget-title">Date Posted</h5>
                <div class="mt-4">
                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="lasthour" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="lasthour">Last Hour</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="oneday" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="oneday">Last 24 hours</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="sevenday" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="sevenday">Last 7 days</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="fourteenday" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="fourteenday">Last 14 days</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-0">
                      <input type="radio" id="onemonth" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="onemonth">Last 30 days</label>
                    </div>
                  </div>
                </div>
              </div>




              <div class="widget mb-4 pb-4 border-bottom">
                <h4 class="widget-title">Job Type</h4>
                <div class="mt-4">
                  <div class="form-group mb-2">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="contract">
                      <label class="custom-control-label" for="contract">Contract Base</label>
                    </div>
                  </div>

                  <div class="form-group mb-2">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="freelancer">
                      <label class="custom-control-label" for="freelancer">Freelancer</label>
                    </div>
                  </div>

                  <div class="form-group mb-2">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="fulltime">
                      <label class="custom-control-label" for="fulltime">Full Time</label>
                    </div>
                  </div>

                  <div class="form-group mb-2">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="parttime">
                      <label class="custom-control-label" for="parttime">Part Time</label>
                    </div>
                  </div>

                  <div class="form-group mb-2">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="remote">
                      <label class="custom-control-label" for="remote">Remote</label>
                    </div>
                  </div>

                  <div class="form-group mb-0">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="intership">
                      <label class="custom-control-label" for="intership">Intership</label>
                    </div>
                  </div>
                </div>
              </div>




              <div class="widget">
                <h4 class="widget-title">Experience</h4>
                <div class="mt-4">
                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="freshers" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="freshers">Fresher</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="oneyearex" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="oneyearex">+1 Year</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="twoyearex" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="twoyearex">+2 Year</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="threeyearex" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="threeyearex">+3 Year</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-0">
                      <input type="radio" id="fiveyearex" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="fiveyearex">+5 Year</label>
                    </div>
                  </div>
                </div>
              </div>
            -->
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-9 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">

            <!--<div class="row" ref="resumesListArea" id="div-with-loading">-->
              <!--<b-tabs
                  pills
                  justified
              >-->
              <b-tabs pills justified v-model="tabIndex">

                <b-tab v-for="(funnel_stage,id) in funnel_stages">
                  <template v-slot:title>
                    <div class="text-center py-2">
                      <h5>Количество кандидатов: {{funnel_stage.number_of_resumes}}</h5>

                    </div>
                  </template>



                  <div
                      class="d-flex border-bottom align-items-center bg-light m-2"
                  >
                    <div class="form-check pl-0 m-2 ">
                      <div class="form-group mb-0">
                        <div class="custom-control custom-checkbox">
                          <input
                              type="checkbox"
                              class="custom-control-input check"
                              id="checkAll"
                              v-model="selectAllCheckbox"
                          />
                          <label class="custom-control-label" for="checkAll"
                          >Выбрать все</label
                          >
                        </div>
                      </div>
                    </div>


                    <b-dropdown
                        class="btn-group dropdown-primary m-2 "
                        text="Действия"
                        variant="secondary"
                    >

                       <!--<div v-if="funnel_stage.number_of_resumes>0">-->
                      <div v-if="funnel_stages.length>0">
                      <a
                          v-b-modal.FunnelTemplate
                          data-toggle="modal"
                          data-target="#EmailTemplate" class="dropdown-item">
                        <git-branch-icon class="fea icon-sm"></git-branch-icon>
                        Переместить
                      </a>

                        <div class="dropdown-divider"></div>
                          <a
                         @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                         class="dropdown-item">
                        <mail-icon
                            class="fea icon-sm"
                        ></mail-icon>
                        Сделать рассылку
                      </a>
                       </div>




                       <div v-else>
                          <a
                            @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                            class="dropdown-item">

                            На данном этапе нет кандидатов

                        </a>
                        </div>





                    </b-dropdown>
                    <b-modal id="FunnelTemplate" title="Выбор этапа воронки" centered>
                      <div class="bg-white p-3 rounded box-shadow">

                        <div class="table-responsive bg-white shadow rounded">
                          <table class="table mb-0 table-center table-nowrap">
                            <thead>
                            <tr>

                              <th scope="col">Этап воронки</th>


                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(funnel_stage,ix) in funnel_stages" v-if="funnel_stages.length">

                              <td @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                                  @click="moveResumeToAnotherFunnelStage(funnel_stage.funnel_stage_order_id)">{{funnel_stage.funnel_stage_name}}
                              </td>

                            </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </b-modal>


                  </div>


                  <div class="col-lg-12 col-12 mb-4 pb-2" v-for="(resume,ix) in resumes" v-if="resume.funnel_stage_order_id===funnel_stage_id">
                 <!-- <div class="col-lg-12 col-12 mb-4 pb-2" v-for="(resume,ix) in resumes"> -->
                      <div class="widget">
                        <ResumeHighlight
                            :vacancy_id="$route.params.vacancy_id"
                            :resume="resume"
                            :vacancyNames="activeVacancies"
                            @index="updateResume"
                            :index="ix"
                            :selectedTrue="selectedTrue"
                            @updateResumeList="updateResumeList"
                        />
                      </div>
                      </div>
                  <!--
                  <div class="widget">
                    <ul class="pagination justify-content-center mb-0">
                      <li class="page-item" @click="PaginationStep(-1)"><a class="page-link" v-if="page.prev" aria-label="Previous" >Пред</a></li>

                      <li class="page-item" :class="x.active" v-for="x in page_arr" @click="PaginationStep(x.page)"><a class="page-link" >{{x.page}}</a></li>

                      <li class="page-item" @click="PaginationStep(-2)"><a class="page-link" v-if="page.next" aria-label="Next">След</a></li>
                    </ul>
                  </div>
                    -->
                  </b-tab>
              </b-tabs>
            </div>
            <!--
            <div class="row">

              <div class="col-12">
                <ul class="pagination justify-content-center mb-0">
                  <li class="page-item"><a class="page-link" v-if="page.prev" aria-label="Previous">Пред</a></li>

                  <li class="page-item" :class="x.active" v-for="x in page_arr" ><a class="page-link" href="javascript:void(0)">{{x.page}}</a></li>

                  <li class="page-item"><a class="page-link" v-if="page.next" aria-label="Next">След</a></li>
                </ul>
              </div>

            </div>
            -->
            <!--
            <li class="page-item active"><a class="page-link" >1</a></li>
            <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
            <li class="page-item"><a class="page-link" href="javascript:void(0)">{{page_max}}</a></li>
            -->


        </div>

    </section>







    <Footer />
    <!--
    <Switcher />


    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
-->
  </div>

</template>

