<template>


  <div class="card w-100 mt-2">
    <div class="public-profile border-0 rounded shadow">

      <div class="row align-items-end">
        <div class="text-lg-left text-center ml-4">

          <div class="form-check form-check-inline" v-if="selectedTrue">
            <div class="form-group mb-0">
              <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck1"
                />
                <label class="custom-control-label" for="customCheck1"
                >          <h4 class="title mb-0" @click="toResume(resume.id)"
                               @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }">
                  {{resume.full_name}}
                </h4>
                </label
                >
              </div>
            </div>
          </div>

          <h4 class="title mb-0" @click="toResume(resume.id)" v-else
              @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }">
            {{resume.full_name}}
          </h4>

          <ul class="list-inline mb-0 mt-2">
            <li class="list-inline-item mr-2"><small class="text-muted h6 mr-2">{{resume.resume_headline}} -</small></li>
            <li class="list-inline-item">
              <map-pin-icon class="fea icon-sm"></map-pin-icon> <small class="text-muted h6 mr-2"> {{resume.location_city}}</small>
            </li>
            <li class="list-inline-item">
              <small class="text-muted h6 mr-2">- возраст: {{resume.age}} лет</small>
            </li>
            <li class="list-inline-item">
              <small class="text-muted h6 mr-2">- опыт: {{resume.experience_total}} лет</small>
            </li>
            <li class="list-inline-item">
              <small class="text-muted h6 mr-2">- з/п: {{resume.salary}}k ₽</small>
            </li>

          </ul>
          <div class="row align-items-end">
            <div class="col text-md-left text-center mt-4 mt-sm-0 ml-2">
              <ul class="list-inline mb-0 mt-3">
                <li class="list-inline-item mr-2" v-for="(skill,ix) in resume.skills" v-if="ix<5">
                  <a href="#" class="btn btn-pills btn-info btn-md mr-2"> {{ skill.skill }} </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
        <!--
      <div class="row align-items-end">
        <div class="col-lg-12 ml-4 mt-4 pt-2" v-for="job in resume.jobs">
          <div class="media">
            <div class="company-logo text-muted h6 mr-3 text-center">
              {{ job.start_date }} - {{ job.end_date_str }}
              <br/>
              {{job.experience_years}} лет
            </div>
            <div class="media-body">
              <h5 class="title mb-0">{{ job.position }}</h5>
              <small class="text-muted company-university"
              >{{ job.company_name }} - {{ job.location_city}}<span v-if="job.location_country!=='Россия'">, {{job.location_country}}</span> </small
              >
            </div>
          </div>
        </div>

      </div>


    </div>
    <b-dropdown class="btn-group m-2" variant="primary" text="Сохранить">
      <b-dropdown-item v-for="(vacancy,i) in vacancyNames" @click="saveResumeInVacancy(i)">
        {{ vacancy.name }}
      </b-dropdown-item>

    </b-dropdown>
      -->

      <div class="widget">
        <ul class="pagination justify-content-left mb-0">
          <li class="page-item" ><a class="page-link"  aria-label="Previous" @click="moveResumeToAnotherFunnelStage(-1)">Пред</a></li>

          <li class="page-item"  ><a class="page-link" @click="removeResume(resume.resume_id)">Удалить</a></li>

          <li class="page-item" ><a class="page-link"  aria-label="Next" @click="moveResumeToAnotherFunnelStage(1)">След</a></li>
        </ul>
      </div>

  </div>

  </div>

</template>

<script>

import {
  MapPinIcon,
} from "vue-feather-icons";
import store from "@/store/store";

export default {
  name: "ResumeHightlight",
  components: {
    MapPinIcon
  },
  props: {
    resume: {
      type: Object,
      required: true,
    },

    vacancyNames: {
      type: Array,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },
    selectedTrue: {
      type: Boolean,
      required: false,
    },

    vacancy_id: {
      type: String,
      required: true,
    },


  },
  data() {
    return {
      hover:false,
      saveResume: {
        user_id: 1,
        resume_id:1,
        save_search_name: '',
        save_search_id: 1,
      },
      move_resumes_sql: {
        user_id: 1,
        vacancy_id:1,
        resume_ids: [],
        funnel_stage_from: 1,
        funnel_stage_to: 1,
        neighbor: true,
      },

    }
  },

  methods: {

    toResume(x){
      let route = this.$router.resolve({path: '/resume/'+x});
      window.open(route.href, '_blank');
    },

    saveResumeInVacancy(i){
      console.log('inside saveResumeInVacancy')

      this.saveResume.resume_id = this.resume.id;
      this.saveResume.user_id = this.$store.state.user_id;

      this.saveResume.save_search_name = this.vacancyNames[i].name;
      this.saveResume.save_search_id = this.vacancyNames[i].id;

      this.axios({
        method: 'post',
        url:
            '' +
            this.$hostname +
            '/prod/resume/save-resume-for-vacancy?access_token=' +
            this.$store.state.token,
        data: this.saveResume,
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      /*.then((res) => {
        if (res.data.changedStatus){

        }
      });
      */
      this.$emit('index', this.index);
    },



    moveResumeToAnotherFunnelStage(x) {
      this.move_resumes_sql.resume_ids = [];



          this.move_resumes_sql.resume_ids.push(this.resume.id)

      this.move_resumes_sql.user_id = store.state.user_id;
      this.move_resumes_sql.vacancy_id = parseInt(this.vacancy_id);
      this.move_resumes_sql.funnel_stage_from = this.resume.funnel_stage_order_id;
      this.move_resumes_sql.funnel_stage_to = x;

      console.log('inside moveResumeToAnotherFunnelStage ' +  ' to = ' + x);
      console.log(this.resume);
      console.log(this.move_resumes_sql);

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/prod/vacancy-funnel/move-to-stage?access_token=' + store.state.token,
        data: this.move_resumes_sql,
      }).then((res) => {

        /*
        for (var i=0; i<res.data.resume_ids.length; i++){
          this.resumes.push(res.data.resume_ids[i]);
          this.resumes_backup.push(res.data.resume_ids[i]);
        }
        console.log(this.resumes);
        */
        this.$emit('updateResumeList',res.data.status);
      });

    },

    removeResume() {


      this.move_resumes_sql.resume_ids.push(this.resume.id)

      this.move_resumes_sql.user_id = store.state.user_id;
      this.move_resumes_sql.vacancy_id = parseInt(this.vacancy_id);


      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/prod/vacancy-funnel/remove-from-stage?access_token=' + store.state.token,
        data: this.move_resumes_sql,
      }).then((res) => {


        this.$emit('updateResumeList',res.data.status);
      });

    },

  }
}
</script>

